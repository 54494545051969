import React, { createElement as h } from "react"
import Layout from "../layout"
import { graphql } from "gatsby"
import { motion } from "framer-motion"
import { Helmet } from "react-helmet"
import Post from "../post"
import Footer from "../footer"

const NewPost = ({ data }) => {
  const post = data.post
  // .responsive class required for Wordpress theme styles.
  return (
    <Layout>
      <motion.main animate="undefined" exit="undefined" className="responsive">
        <Helmet></Helmet>
        <Post
          title={post.title}
          author={post.author.node}
          imageUrl={post.acf?.postHeaderImage?.sourceUrl}
          previousArticle={post.acf?.previousArticle?.url}
          nextArticle={post.acf?.nextArticle?.url}
          content={post.content}
          prev={data.prev}
          next={data.next}
        />
        <Footer />
      </motion.main>
    </Layout>
  )
}
export default NewPost

export const query = graphql`
  query($id: String!, $previous: String, $next: String) {
    post: wpPost(id: { eq: $id }) {
      title
      content
      author {
        node {
          name
        }
      }
      featuredImage {
        node {
          sourceUrl
        }
      }
      acf: postCustomFields {
        postHeaderImage {
          sourceUrl
        }
        previousArticle {
          title
          url
        }
        nextArticle {
          title
          url
        }
      }
    }
    prev: wpPost(id: { eq: $previous }) {
      path: uri
      title
    }
    next: wpPost(id: { eq: $next }) {
      path: uri
      title
    }
  }
`
